<app-nav></app-nav>
<div class="jumbotron jumbotron-fluid">
  <div class="container">
    <h1 id="title" class="display-4 mb-2">Welcome to the Barz BRO!</h1>
    <h2 id="title" class="lead mt-1 mb-2">Check out the vibes of the bars tonight or check out top bars in your area!</h2>
    <h3 id="start" class="lead mt-1 mb-2">All rated by fellow bros!</h3>
    <h5 id="start" class="lead mt-1">Pick a city or college campus to get started!</h5>
  </div>
  <div class="container">
    <img src="../../assets/img/home_page.jpg" class="img-fluid">
  </div>
  
</div>

  

