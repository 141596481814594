<div class = "card text-white bg-dark">
    <div class="card-header">
        <h2 id="bar" class="font-weight-bold">{{post.Bar | barName}}</h2>
    </div>
    <div class="card-body">
        <h5 id="content" class="card-text">{{post.Content}}</h5>
        <a class="btn btn-primary mt-2 mb-2 text-light" role="button" (click)="editPost()">Edit Post</a>
    </div>
    <div class="card-footer">
        <div class="row">
            <p class="card-text">{{post.Username}}</p>
            <p class="card-text">User Rating: {{post.Rating}}/10</p>
        </div>
       
        <div class="row">
            <p class="card-text">{{post.Timestamp * 1000 | date: 'short'}}</p>
            <p class="card-text">{{ neighborhood | barName}}{{post.Location}}</p>
        </div>    
    </div>
</div>

<div *ngIf="edit">
    <app-edit-post (editEvent)="editStatus($event)" [post]='post'></app-edit-post>
</div>
