// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:de074c4c-2e6d-456d-84b6-b11bc7fa2eb6",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_FFPmb68u4",
    "aws_user_pools_web_client_id": "3gc6oft576ol7ad0sf741n5jtg",
    "oauth": {},
    "aws_content_delivery_bucket": "frontend-20200306164135-hostingbucket-test",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "http://frontend-20200306164135-hostingbucket-test.s3-website.us-east-2.amazonaws.com"
};


export default awsmobile;
