<div class="container">
  <h1>Create A New Post</h1>
  <form (ngSubmit)="submit()">
    <div class="form-group">
      <label for="location">City*</label>
      <select class="form-control" id="location" [formControl]="location" required>
        <option>Columbus</option>
        <option>Chicago</option>
        <option>New York</option>
        <option>Denver</option>
        <option>DC</option>
        <option>Minneapolis</option>
      </select>
    </div>

    <div class="form-group">
      <label for="bar">Bar*</label>
      <input type="text" class="form-control" [formControl]="bar" required>
    </div>

    <div class="form-group">
      <label for="bar">Neighborhood*</label>
      <input type="text" class="form-control" [formControl]="neighborhood" required>
    </div>


    <div class="form-group">
      <label for="rating">Rating*</label>
      <select class="form-control" id="rating" [formControl]="rating" required>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>10</option>
      </select>
    </div>

    <div class="form-group">
      <label for="content">What's Good?*</label>
      <textarea class="form-control" id="content" [formControl]="content" rows="3" required></textarea>
    </div>


    <button type="signup" class="btn btn-primary" (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-primary ml-5">Submit</button>


  </form>
</div>
